import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import {TableHeaderColumn} from 'react-bootstrap-table'
import {DropdownButton, MenuItem, OverlayTrigger, Popover} from 'react-bootstrap'

import moment from 'moment'
import {Icon} from '@ant-design/compatible'
import {Button, Empty, message, Space, Spin, Tooltip} from 'antd'
import {
  CloseOutlined,
  DeleteFilled,
  DeleteOutlined,
  FileZipOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons'
import {T} from "../../utils/translationUtils"

import {
  hasArchiveRights,
  hasBatchAssignRights,
  hasCancelRights,
  hasDeleteRights,
  hasHardDeleteRights,
  renderCAlgorithm,
  renderResultInfo
} from '../../utils/appHelper'
import {__} from '../../utils/translationUtils'
import * as config from '../../constants/globalConfiguration'

import {entryFeatures} from '../../constants'

import DooOrderButton from '../DooOrderButton'
import {api} from '../../providers/ApiProvider'
import EditOrderSelectAssigneeModal from '../EditOrderSelectAssigneeModal'
import {StickyScrollableTable} from '../../providers/StyleProvider/styles'

import ConfirmationModal from './OrderActions/ConfirmationModal'
import ActionStatusPanel from './OrderActions/ActionStatusPanel'
import ArchivedProgressStatus from "./OrderActions/ActionsProgressStatus";
import ActionsMenu from "./OrderActions/ActionsMenu";
import * as S from "./OrderActions/styles";

import {JobStatusEnum} from "./OrderActions/constants";
import {extractDetailedDisplayableColumn, extractDisplayConfiguration} from "../../utils/attributeUtils";
import {statusToAction} from "./OrderActions/utils";
import EditOrderModal from "../EditOrderModal";

class ReportDataTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      bulkAssignLoading: false,
      selectedRows: null,
      selectUserModal: {open: false},
      editedOrder: false,
      actionExecution: false,
      jobs: props.data?.reduce((acc, item) => {
        const {cancelled, archived, deleted} = item.orderOverview

        const status = Object.entries({cancelled, archived, deleted})
          .filter(([_, value]) => value)
          .reduce((acc, [key, _]) => ({...acc, [key]: key}), {})

        const action = statusToAction(status)

        if (action) {
          acc[item.id] = {
            status: JobStatusEnum.alreadyDone,
            action,
            position: Object.keys(acc).length
          }
        }
        return acc
      }, {})
    }

    this.toggleEditOrder = this.toggleEditOrder.bind(this)
    this.toggleActionConfirmationModal = this.toggleActionConfirmationModal.bind(this)
    this.setJobStatus = this.setJobStatus.bind(this)
  }


  toggleActionConfirmationModal(value) {
    this.setState((state) => ({actionExecution: value}))
  }

  toggleEditOrder(orderId) {
    this.setState((state) => ({editedOrder: orderId}))
  }

  setJobStatus(id, status, action) {
    this.setState((state) => ({
      jobs: {
        ...state.jobs,
        [id]: {
          ...state.jobs?.[id],
          ...(action ? {action} : {}),
          status,
          position: Object.keys(state.jobs).length
        }
      }
    }))
  }

  renderPagination() {
    const sizePerPageList = this.props.type.toLowerCase() === 'report' ? [25, 50, 100, 500, 1000, 2000] : config.pageSizeOptions

    return (
      <DropdownButton
        onSelect={this.props.setPageSize.bind(this)}
        bsStyle="link"
        title={this.props.pagination.pageSize}
        className="pagination-dropdown btn-link"
        id="pageSize"
      >
        {sizePerPageList.map((val, i) => <MenuItem key={i} eventKey={val}>{val}</MenuItem>)}
      </DropdownButton>
    )
  }

  orderTypeBorderEnabled(viewConfiguration) {
    if (!viewConfiguration?.settings?.entryFeatures?.available) {
      return false
    }
    return viewConfiguration.settings.entryFeatures.available.includes(entryFeatures.ORDER_TYPE)
  }

  borderOrderTypeColorFormater(_, item, i) {
    const {data, viewConfiguration} = this.props
    let color = null
    if (data && data[i] && data[i].orderOverview && data[i].orderOverview.orderType) {
      color = data[i].orderOverview.orderType.color || {}
    }

    const style = {}

    if (color && viewConfiguration && viewConfiguration.settings && viewConfiguration.settings.entryFeatures && Array.isArray(viewConfiguration.settings.entryFeatures.available) && this.orderTypeBorderEnabled(viewConfiguration)) {
      style.borderTop = '2px solid transparent'
      //style.borderLeft = "4px solid " + color
      style.boxShadow = 'inset 5px 0 0 ' + color
      style.borderLeft = '1px solid transparent'
      //style.borderWidth = "0"
      return style
    }

    return style
  }

  render() {
    const {
      reportSpec,
      updateData,
      pagination,
      isLoading,
      users,
      onPageChange,
      onSortChange,
      type,
      location,
      selectedColumns
    } = this.props

    const {priorityValues} = global.constants

    const actionsFormatter = (cell, _row) => {
      const {viewConfiguration} = this.props

      if (!_row?.accessRightsInfo) return null

      const {settings} = viewConfiguration || {}

      const hasJobStatus = this.state.jobs[_row.id]

      if (hasJobStatus) {
        return <ActionStatusPanel
          row={_row}
          orders={this.state.jobs}
          setJobStatus={this.setJobStatus}/>
      }

      return (
        <div>
          {settings && settings.entryFeatures && Array.isArray(settings.entryFeatures.available) && (
            <div style={CellTimelineWrapper}>

              <div style={CellDeadline}>
                {settings.entryFeatures.available.includes(entryFeatures.DEADLINE) && _row.orderOverview && _row.orderOverview.deadline && _row.orderOverview.deadline.deadline && (
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="top"
                    overlay={(
                      <Popover className="capitalize" id={`popover-trigger-deadline-${_row.id}`} title={__('deadline')}>
                        {moment(_row.orderOverview.deadline.deadline).format(config.appDefaults.dateTimeFormat)}
                      </Popover>)}
                  >
                    <Icon type="clock-circle" theme="filled"
                          style={{color: _row.orderOverview.deadline.color, fontSize: '1.0em'}}/>
                  </OverlayTrigger>)}
              </div>

              <div style={CellPriority}>
                {settings.entryFeatures.available.includes(entryFeatures.PRIORITY) && _row.orderOverview && _row.orderOverview.priority && priorityValues[_row.orderOverview.priority] && (
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="top"
                    overlay={(
                      <Popover className="capitalize" id={`popover-trigger-priority-${_row.id}`} title={__('priority')}>
                        {priorityValues[_row.orderOverview.priority].name}
                      </Popover>)}
                  >
                    <Icon
                      type={priorityValues[_row.orderOverview.priority].icon}
                      rotate={priorityValues[_row.orderOverview.priority].iconRotation || 0}
                      className="priority-icon"
                      style={{fontSize: '1.0em'}}
                    />
                  </OverlayTrigger>)}
              </div>

            </div>)}

          <div style={CellCtaWrapper}>
            <DooOrderButton orderDetails={_row} location={location}/>
            <div style={CellOverview}>
              <Link
                to={`/orders/${_row.id}/overview`}
                className="btn btn-default btn-sm btn-no-padding"
                style={Full}
              >
                <Icon type="eye" size="large" style={{fontSize: '1.3rem'}}/>
              </Link>
            </div>
          </div>
        </div>
      )
    }

    const onRowSelect = (row, isSelected) => {
      if (isSelected) {
        this.setState((state) => ({
          selectedRows: [...(state.selectedRows || []).filter((item) => item.id !== row.id), row]
        }))
      } else {
        this.setState((state) => ({
          selectedRows: state.selectedRows.filter((item) => item.id !== row.id)
        }))
      }
    }

    const rowActions = (cell, row) => (
      <S.ActionsOverlay>
        <ActionsMenu
          orderId={row.id}
          triggerActionCallback={this.setJobStatus}
          editOrder={this.toggleEditOrder}
        />
      </S.ActionsOverlay>
    )

    const handleBatchAssign = (processIdAndOwnershipIdMap, userEntityIds) => {
      if (processIdAndOwnershipIdMap == null || Object.keys(processIdAndOwnershipIdMap)?.length === 0) {
        message.error(__('Please select multiple processes'))
        return
      }
      this.setState({bulkAssignLoading: true})
      const payload = {
        processIdAndOwnershipIdMap, assignees: userEntityIds
      }

      api.post(`/processes/ownerships/assign`, payload)
        .then((response) => {
          this.setState({
            bulkAssignLoading: false,
          })
          if (response?.data?.failed?.length === 0) {
            message.success(__('Batch assign done successfully for all processes'))
            this.props.refreshData()
          } else {
            message.success(__('Batch assign done successfully, but some have not been assigned'))
            message.warning(`${__('The following process ids have not been assigned: ')} ${response?.data?.failed.map((item) => item.processId).join(', ')}`)
          }
        })
        .catch(() => {
          message.error(__('Something went wrong performing processes batch assign'))
        })
    }

    const onSelectAll = (isSelected, rows) => {
      if (isSelected) {
        for (let i = 0; i < rows.length; i++) {
          onRowSelect(rows[i], isSelected)
        }
      } else {
        this.setState({
          selectedRows: []
        })
      }
    }

    const selectEnabled = (type.toLowerCase() === 'view' && this.props?.viewConfiguration?.settings?.allowBatchOperations && hasBatchAssignRights(this.props.guiUser.rights))
    const orderTypeBorderEnabled = this.orderTypeBorderEnabled(this.props?.viewConfiguration)
    const selectRowProp = {
      mode: selectEnabled ? 'checkbox' : '',
      onSelect: onRowSelect,
      onSelectAll: onSelectAll,
      showColumnsSearch: true,
      columnWidth: '20px',
      selected: this.state.selectedRows?.map(row => row?.id)
    }

    const batchAssign = () => {
      this.setState({
        selectUserModal: {open: true}
      })
    }

    function closeSelectUserModal() {
      this.setState({selectUserModal: {open: false}})
    }

    const batchExecuteActions = (action) => {
      if (!this.state?.selectedRows?.length) {
        message.error(__('Please select multiple items'))
        return
      }

      this.toggleActionConfirmationModal(action)
    }

    const handleBatchExecuteActions = (eligibleOrders, action) => {
      this.setState((state) => {
        let jobs = Object.entries(state.jobs).length
        return {
          actionExecution: false,
          selectedRows: [],
          jobs: eligibleOrders.reduce((acc, item,) => {
            const currentStatus = state.jobs[item.id]?.status
            if (!currentStatus || currentStatus === JobStatusEnum.failed) {
              acc[item.id] = {
                status: JobStatusEnum.toBeProcessed,
                action,
                position: jobs++
              }
            }
            return acc
          }, {...state.jobs})
        }
      })
    }

    return (
      <Spin spinning={this.state.bulkAssignLoading} tip={__('It may take a while')}>
        <div>
          {reportSpec && reportSpec.displayAttributes.length > 0 && (<>
            {
              (this.props?.viewConfiguration?.settings?.allowBatchOperations &&
                !!this.state.selectedRows?.length) &&
              (
                <Space className="batch-operations">
                  <Tooltip title={__(T.assign)}>
                    <Button className="outlined" disabled={!hasBatchAssignRights(this.props.guiUser.rights)}
                            icon={<UserSwitchOutlined/>}
                            onClick={batchAssign}/>
                  </Tooltip>
                  <Tooltip title={__(T.cancel)}>
                    <Button className="outlined" disabled={!hasCancelRights(this.props?.guiUser?.rights)}
                            icon={<CloseOutlined/>}
                            onClick={() => batchExecuteActions('cancel')}/>
                  </Tooltip>
                  <Tooltip title={__(T.archive)}>
                    <Button className="outlined" disabled={!hasArchiveRights(this.props?.guiUser?.rights)}
                            icon={<FileZipOutlined/>}
                            onClick={() => batchExecuteActions('archive')}/>
                  </Tooltip>

                  <Tooltip title={__(T.delete)}>
                    <Button className="outlined" disabled={!hasDeleteRights(this.props?.guiUser?.rights)}
                            icon={<DeleteOutlined/>}
                            onClick={() => batchExecuteActions('delete')}/>
                  </Tooltip>
                  <Tooltip title={__(T.hard_delete)}>
                    <Button className="outlined" disabled={!hasHardDeleteRights(this.props?.guiUser?.rights)}
                            icon={<DeleteFilled/>}
                            onClick={() => batchExecuteActions('hardDelete')}/>
                  </Tooltip>
                </Space>
              )
            }
            {
              !!Object.values(this.state.jobs)?.filter((order) => order.status !== JobStatusEnum.alreadyDone)?.length && (
                <ArchivedProgressStatus
                  jobs={Object.values(this.state.jobs)?.filter((order) => order.status !== JobStatusEnum.alreadyDone)}/>
              )
            }
            <StickyScrollableTable
              // multiColumnSort={ 2 }
              keyField="id"
              //version='4'
              inverse={true}
              data={this.props.data}
              ref="reportDataTable"
              //trStyle={{borderBottom: "5px solid #fafafa"}}
              //trClassName="order-listing-row"
              remote
              pagination
              hover
              striped
              bordered={true}
              className={'large-table' + (selectEnabled ? ' selectable' : '') + (orderTypeBorderEnabled ? ' bordered' : '')}
              trClassName={(row, i) => this.state.jobs[row.id] ?
                `order-action-panel-row${(this.state.jobs[row.id].action === 'hardDelete') ? ' disabled-row' : ''}` : ''}
              fetchInfo={{dataTotalSize: pagination.totalCount}}
              selectRow={selectRowProp}
              options={{
                onPageChange: onPageChange.bind(this),
                onSortChange: onSortChange.bind(this),
                noDataText: isLoading ? __('loading_data') : <Empty description={__('NoDataFound')}/>,
                hideSizePerPage: false,
                firstPage: 'First',
                lastPage: 'Last',
                paginationShowsTotal: renderResultInfo.bind(this),
                page: pagination.pageNumber,
                sizePerPageList: config.pageSizeOptions,
                sizePerPage: pagination.pageSize
              }}
            >
              <TableHeaderColumn
                width="140"
                tdStyle={this.borderOrderTypeColorFormater.bind(this)}
                hidden={type.toLowerCase() === 'report'}
                dataFormat={actionsFormatter.bind(this)}
              />

              {reportSpec.displayAttributes.map((a, i) => {
                const columnData = extractDetailedDisplayableColumn({
                  field: a,
                  selectedColumns,
                  detailedFields: this.props.attributesConfiguration
                })
                if (!columnData) return null
                return (
                  <TableHeaderColumn
                    dataSort
                    dataFormat={(cell, row) => renderCAlgorithm(row.displayData[columnData.id], row, columnData, extractDisplayConfiguration(this.props.reportSpec.displayAttributes, a.attributeId))}
                    key={columnData.id}
                    dataField={columnData.id}
                  >
                    {__(a.propertyLabel || columnData.propertyLabel)}
                  </TableHeaderColumn>)
              })}

              <TableHeaderColumn
                width="0px"
                dataFormat={rowActions.bind(this)}
                columnClassName="hover-actions-column"
              />
            </StickyScrollableTable>

          </>)}
          <EditOrderSelectAssigneeModal
            open={this.state.selectUserModal.open}
            selectedItems={this.state.selectedRows}
            guiUser={this.props?.guiUser}
            closeModal={closeSelectUserModal.bind(this)}
            onSubmit={(processIdAndOwnershipIdMap, userEntityIds) => handleBatchAssign(processIdAndOwnershipIdMap, userEntityIds)}
          />
          <ConfirmationModal
            action={this.state.actionExecution}
            open={!!this.state.actionExecution}
            selectedItems={this.state.selectedRows}
            jobsStatus={this.state.jobs}
            onCancel={() => this.toggleActionConfirmationModal(null)}
            onSubmit={handleBatchExecuteActions.bind(this)}
          />
          {
            this.state.editedOrder && (
              <EditOrderModal
                attributesConfiguration={this.props.attributesConfiguration}
                open={this.state.editedOrder}
                closeModal={() => this.toggleEditOrder(false)}
                orderId={this.state.editedOrder}
                location={location}
                guiUser={this.props.guiUser}
                users={users}
                updateData={updateData.bind(this)}
              />
            )
          }
        </div>
      </Spin>)
  }
}

export const Cell = {}
export const Full = {
  paddingLeft: '0', paddingRight: '0', width: '100%'
}

export const CellTimelineWrapper = {
  display: 'flex', justifyContent: 'space-between', marginBottom: '.5rem'

}

export const CellDeadline = {}

export const CellPriority = {}

export const CellCtaWrapper = {
  display: 'grid', gridTemplateColumns: 'repeat(2, 50%)', gridGap: '0.7rem', textAlign: 'center', margin: '0 12px'
}

export const CellDoo = {}
export const CellDooText = {fontSize: '1.3rem'}

export const CellEdit = {}

export const CellOverview = {}

ReportDataTable.propTypes = {
  data: PropTypes.array,
  reportSpec: PropTypes.object,
  selectedColumns: PropTypes.array,
  attributesConfiguration: PropTypes.array,
  users: PropTypes.array,
  guiUser: PropTypes.object,
  pagination: PropTypes.object,
  type: PropTypes.string,
  isLoading: PropTypes.bool,
  updateData: PropTypes.func,
  refreshData: PropTypes.func,
  onPageChange: PropTypes.func,
  onSortChange: PropTypes.func,
  setPageSize: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  viewConfiguration: PropTypes.object
}

ReportDataTable.defaultProps = {
  data: [],
  reportSpec: [],
  selectedColumns: [],
  users: [],
  attributesConfiguration: [],
  guiUser: {},
  pagination: {},
  type: ''
}

export default ReportDataTable

